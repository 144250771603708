var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        placement: "right",
        visible: _vm.visible,
        "mask-closable": false,
        "after-close": _vm.afterVisibleChange,
        width: "915px",
      },
      on: { cancel: _vm.onClose, ok: _vm.onSubMin },
    },
    [
      _c("template", { slot: "title" }, [
        _c("span", { staticClass: "modal_title" }, [_vm._v("表头设置")]),
      ]),
      _c("div", [
        _c("span", { staticClass: "info" }, [_vm._v("已选择表头")]),
        _c("span", { staticClass: "tip" }, [
          _vm._v(
            "可拖拽调整表头显示顺序，其中【姓名/工号/部门/岗位/考勤确认】为固定顺序不受拖拽影响"
          ),
        ]),
        _c(
          "div",
          { staticClass: "list_margin" },
          [
            _c(
              "vueDragGable",
              {
                staticClass: "wrapper",
                attrs: { move: _vm.onMove },
                on: { end: _vm.end },
                model: {
                  value: _vm.displayDragList,
                  callback: function ($$v) {
                    _vm.displayDragList = $$v
                  },
                  expression: "displayDragList",
                },
              },
              [
                _c(
                  "transition-group",
                  _vm._l(_vm.displayDragList, function (item) {
                    return _c(
                      "a-button",
                      {
                        key: item.id,
                        staticClass: "not_shown_class",
                        attrs: {
                          "is-blur": false,
                          type: "primary",
                          disabled: item.d,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDisplayList(item)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.name) + " "),
                        !item.d
                          ? _c("svg", { staticClass: "iconpark-icon" }, [
                              _c("use", { attrs: { href: "#close-small" } }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticStyle: { "margin-top": "44px" } }, [
        _c("span", { staticClass: "info" }, [_vm._v("未选择信息")]),
        _c(
          "div",
          { staticClass: "list_margin" },
          _vm._l(_vm.notShownListNull, function (item) {
            return _c(
              "a-button",
              {
                key: item.id,
                staticClass: "not_shown_class",
                attrs: { "is-blur": false },
                on: {
                  click: function ($event) {
                    return _vm.getNotShownList(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }